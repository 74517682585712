import BaseComponent from "@/components/base/el/BaseComponent";
// import BaseResize from "@/components/base/el/BaseResize";

export default {
    name: "BaseTextComponent",
    mixins: [
      BaseComponent,
      // BaseResize
    ],
    render(createElement) {
        let tag = this.tag;
        let data = this.data_object;
        let children = []
        if (this.$slots.default) {
          children = this.$slots.default
        }

        return createElement(tag, data, children);
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.window_width = window.innerWidth
      })
    },
    data() {
      return {
        is_text: true,
        window_width: window.innerWidth,
        tag: "",
        editing_text: false
      }
    },
    computed: {
      desktop_transform() {
        return this.component_data.attr.style.transform_data.desktop || 'matrix(1, 0, 0, 1, 0, 0)';
      },
      tablet_transform() {
        return this.component_data.attr.style.transform_data.tablet || 'matrix(1, 0, 0, 1, 0, 0)';
      },
      mobile_transform() {
        return this.component_data.attr.style.transform_data.mobile || 'matrix(1, 0, 0, 1, 0, 0)';
      },
      // mobile_flex() {
      //   return this.component_data.attr.style.flex_data.mobile;
      // },
      // tablet_flex() {
      //   return this.component_data.attr.style.flex_data.tablet;
      // },
      // desktop_flex() {
      //   return this.component_data.attr.style.flex_data.desktop;
      // },
      // padding_data_top() {
      //   return (reponsive) => {
      //     if (!this.component_data.attr.style.padding_data || !this.component_data.attr.style.padding_data[reponsive]) return null
      //     return this.component_data.attr.style.padding_data[reponsive].top;
      //   }
      // },
      // padding_data_right() {
      //   return (reponsive) => {
      //     if (!this.component_data.attr.style.padding_data || !this.component_data.attr.style.padding_data[reponsive]) return null
      //     return this.component_data.attr.style.padding_data[reponsive].right;
      //   }
      // },
      // padding_data_bottom() {
      //   return (reponsive) => {
      //     if (!this.component_data.attr.style.padding_data || !this.component_data.attr.style.padding_data[reponsive]) return null
      //     return this.component_data.attr.style.padding_data[reponsive].bottom;
      //   }
      // },
      // padding_data_left() {
      //   return (reponsive) => {
      //     if (!this.component_data.attr.style.padding_data || !this.component_data.attr.style.padding_data[reponsive]) return null
      //     return this.component_data.attr.style.padding_data[reponsive].left;
      //   }
      // },
      // desktop_size() {
      //   return this.component_data.attr.style.size.desktop
      // },
      // tablet_size() {
      //   return this.component_data.attr.style.size.tablet
      // },
      // mobile_size() {
      //   return this.component_data.attr.style.size.mobile
      // },
      contenteditable() {
        return this.editing_text && this.$store.state.edit_mode && !this.component_data.attr['deepsel-esc']
      },
      styling() {
        let styling = JSON.parse(JSON.stringify(this.component_data.attr.style))
        delete styling.transform_data
        let transform
        // , flex, size, padding_top, padding_right, padding_bottom, padding_left
        if (this.window_width < 600) {
          transform = this.mobile_transform
          // flex = this.mobile_flex
          // size = this.mobile_size
          // padding_top = this.padding_data_top('mobile')
          // padding_right = this.padding_data_right('mobile')
          // padding_bottom = this.padding_data_bottom('mobile')
          // padding_left = this.padding_data_left('mobile')
  
        } else if (this.window_width < 992) {
          transform = this.tablet_transform
          // padding_top = this.padding_data_top('tablet')
          // padding_right = this.padding_data_right('tablet')
          // padding_bottom = this.padding_data_bottom('tablet')
          // padding_left = this.padding_data_left('tablet')
          // flex = this.tablet_flex
          // size = this.tablet_size
        } else {
          // transform = this.desktop_transform
          // padding_top = this.padding_data_top('desktop')
          // padding_right = this.padding_data_right('desktop')
          // padding_bottom = this.padding_data_bottom('desktop')
          // padding_left = this.padding_data_left('desktop')
          // flex = this.desktop_flex
          // size = this.desktop_size
        }
        if (transform) styling.transform = transform
        // if (flex) styling.flex = flex + ' !important'
        // if (size) {
        //   styling.width = size[0] + 'px !important'
        //   styling.height = size[1] + 'px !important'
        // }
        // if (padding_top !== undefined) styling.paddingTop = padding_top + 'px !important'
        // if (padding_right !== undefined) styling.paddingRight = padding_right + 'px !important'
        // if (padding_bottom !== undefined) styling.paddingBottom = padding_bottom + 'px !important'
        // if (padding_left !== undefined) styling.paddingLeft = padding_left + 'px !important'
  
        return styling
      },
      data_object() {
        let class_array = this.$store.state.edit_mode ? [{
          "deepsel-focus": this.is_editing && !this.$store.state.editing_text,
          "deepsel-text-edit": this.is_editing && this.$store.state.editing_text
        }] : []
        if (this.component_data.attr && this.component_data.attr.class) {
          let class_value
  
          if (Array.isArray(this.component_data.attr.class)) {
            class_value = this.component_data.attr.class.join(" ")
          } else class_value = this.component_data.attr.class
  
          class_array.push(class_value)
        }
  
        let res = {
          class: class_array,
          attrs: {
            "data-id": this.data_id,
            contenteditable: this.contenteditable,
            draggable: false,
          },
          style: this.styling,
        }
  
        if (this.$store.state.edit_mode) {
          res = {
            ...res,
            on: {
              focusout: this.focus_out,
              // input: this.on_input,
              mouseenter: this.mouse_enter,
              mousedown: this.mouse_down,
              // contextmenu: this.contextmenu
            }
          }
        }
  
        return res
      },
      left() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          return !this.editing.component_data.attr.style.textAlign || this.editing.component_data.attr.style.textAlign === "left";
        } else return false;
      },
      center() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          return this.editing.component_data.attr.style.textAlign === "center";
        } else return false;
      },
      right() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          return this.editing.component_data.attr.style.textAlign === "right";
        } else return false;
      },
      justify() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          return this.editing.component_data.attr.style.textAlign === "justify";
        } else return false;
      },
    },
    methods: {
      toggle_edit_text() {
        if (this.is_empty_shell && this.$children[0] && this.$children[0].is_text) return this.$children[0].toggle_edit_text()
        this.editing_text = !this.editing_text
        // this.can_drag = !this.can_drag
        this.$store.state.editing_text = this.editing_text
  
        if (this.editing_text) {
          setTimeout(() => {
            this.$el.focus();
          }, 0)
        }
      },
      async focus_out() {
        if (this.component_data.child[0].node === 'text') {
          this.$el.innerHTML = this.$el.textContent
          this.component_data.child[0].text = this.$el.textContent
        }
      },
      on_align_left() {
          if (!this.component_data.attr.style.textAlign) {
              this.component_data.attr.style.textAlign = "left";
          } else this.component_data.attr.style.textAlign = null;
      },
      on_align_center() {
          if (!this.component_data.attr.style.textAlign) {
              this.component_data.attr.style.textAlign = "center";
          } else this.component_data.attr.style.textAlign = "center";
      },
      on_align_right() {
          if (!this.component_data.attr.style.textAlign) {
              this.component_data.attr.style.textAlign = "right";
          } else this.component_data.attr.style.textAlign = "right";
      },
      on_align_justify() {
        if (!this.component_data.attr.style.textAlign) {
          this.component_data.attr.style.textAlign = "justify";
        } else this.component_data.attr.style.textAlign = "justify";
      }
    }
}
