import BaseTextComponent from "@/components/base/el/BaseTextComponent";
import {get_att_data,is_empty, is_object, is_array} from "@/components/base/utils";

import ComponentMap from "@/components/base/el/ComponentMap";

const BaseFormDataComponent = () => import("@/components/base/BaseFormDataComponent"),
    BaseVideoComponent = () => import("@/components/base/BaseVideoComponent"),
    BasePopupComponent = () => import("@/components/base/BasePopupComponent"),
    BaseCarouselComponent = () =>
        import("@/components/base/BaseCarouselComponent"),
    BaseDropdownComponent = () =>
        import("@/components/base/BaseDropdownComponent"),
    BaseHTMLContentComponent = () =>
        import("@/components/base/BaseHTMLContentComponent"),
    BaseMenuComponent = () => import("@/components/base/menu/BaseMenuComponent");


export default {
    name: "BaseNavComponent",
    mixins: [BaseTextComponent],
    render(createElement) {
        return this.make_element_tree(createElement, this.component_data);
    },
    data() {
        return {
            is_text: false,
            isNav: true,
            deepsel_nav_contents: '',
            deepsel_nav_items: ''
        };
    },
    props: {
        component_data: Object,
    },
    computed: {
        data_object() {
            let class_array = [{"deepsel-focus": this.is_editing}]
            if (this.component_data.attr && this.component_data.attr.class) {
                let class_value

                if (Array.isArray(this.component_data.attr.class)) {
                    class_value = this.component_data.attr.class.join(" ")
                } else class_value = this.component_data.attr.class

                class_array.push(class_value)
            }

            return {
                class: class_array,
                attrs: {
                    contenteditable: this.$store.state.edit_mode && !this.component_data.attr['deepsel-esc'],
                    draggable: false,
                },
                style: this.styling,
                on: {
                    focusout: this.focus_out,
                    // input: this.on_input,
                    mouseenter: this.mouse_enter,
                    mousedown: this.mouse_down,
                },
            };
        },
    },
    methods: {
        add() {
            this.deepsel_nav_contents.child.push(JSON.parse(JSON.stringify(this.deepsel_nav_contents.child[this.deepsel_nav_contents.child.length - 1])))
            this.deepsel_nav_items.child.push(JSON.parse(JSON.stringify(this.deepsel_nav_items.child[this.deepsel_nav_items.child.length - 1])))
        },
        remove() {
            if (this.deepsel_nav_contents.child.length > 1) {
                this.deepsel_nav_contents.child.pop()
                this.deepsel_nav_items.child.pop()
            }
        },
        is_special_div(element_data) {
            if (element_data.tag !== "div") return false;

            if (element_data.child && is_array(element_data.child)) {
                let text_nodes = element_data.child.filter((c) => c.node === "text" && /\S/.test(c.text)) //text must not be all whitespace
                if (text_nodes.length > 0) return true;
            }

            if (
                element_data.attr &&
                element_data.attr.style &&
                element_data.attr.style.backgroundImage
            ) return true

            if (
                element_data.attr &&
                'deepsel-container' in element_data.attr
            ) return true

            return false;
        },
        is_special_section(element_data) {
            if (element_data.tag !== "section") return false;

            if (element_data.child && is_array(element_data.child)) {
                let text_nodes = element_data.child.filter((c) => c.node === "text" && /\S/.test(c.text)) //text must not be all whitespace
                if (text_nodes.length > 0) return true;
            }

            if (
                element_data.attr &&
                element_data.attr.style &&
                element_data.attr.style.backgroundImage
            ) return true;

            if (
                element_data.attr &&
                'deepsel-container' in element_data.attr
            ) return true

            return false;
        },
        focus_out() {
        },
        toNavItems(e,i) {
            e.preventDefault()
            console.log(window.$(`*[href='#nav-${this.data_id}-${i+1}']`));
            window.$(`*[href='#nav-${this.data_id}-${i+1}']`).tab('show') 
        },
        make_element_tree(createElement, element_data, options = {}, context = null) {
            let data = {}
            let el, props, attrs, style, children

            if (element_data.attr && 'deepsel-nav' in element_data.attr) {
                let class_array = [{"deepsel-focus": this.is_editing}]
                if (this.component_data.attr && this.component_data.attr.class) {
                    let class_value

                    if (Array.isArray(this.component_data.attr.class)) {
                        class_value = this.component_data.attr.class.join(" ")
                    } else class_value = this.component_data.attr.class

                    class_array.push(class_value)
                }
                data = {
                    class: class_array,
                    attrs: {
                        contenteditable: this.$store.state.edit_mode && !this.component_data.attr['deepsel-esc'],
                        draggable: false,
                    },
                    style: this.styling,
                    on: {
                        focusout: this.focus_out,
                        // input: this.on_input,
                        mouseenter: this.mouse_enter,
                        mousedown: this.mouse_down,
                    },
                }
            }

            if (element_data.attr && 'deepsel-nav-contents' in element_data.attr) {
                this.deepsel_nav_contents = element_data
            }
            if (element_data.attr && 'deepsel-nav-items' in element_data.attr) {
                this.deepsel_nav_items = element_data
            }
            if (element_data.attr && element_data.attr.class && element_data.attr.class.includes('nav-item') && context && context.index !== undefined) {
                data = {
                    on: {
                        click: $event => this.toNavItems($event, context.index)
                    },
                }
            }

            [el, props] = this.get_el_props(element_data, context)
            attrs = this.get_attrs(element_data, context)
            children = this.get_children(element_data, createElement, context)
            style = this.get_style(element_data)

            if (props) data.props = props
            if (attrs) data.attrs = {...attrs, ...data.attrs}
            if (style) data.style = {...style, ...data.style}

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },
        get_el_props(element_data) {
            let el = element_data.tag;
            let props = null;

            if (element_data.attr) {
                if ('deepsel-menu' in element_data.attr) {
                    el = BaseMenuComponent
                    props = {component_data: element_data}
                    return [el, props]
                }
                if ('deepsel-html-content' in element_data.attr) {
                    el = BaseHTMLContentComponent
                    props = {component_data: element_data}
                    return [el, props]
                }
                if ( (element_data.attr.class && element_data.attr.class.split(" ").includes("modal") ) || "deepsel-popup" in element_data.attr) {
                    el = BasePopupComponent;
                    props = {component_data: element_data};
                    return [el, props];
                }
                if ("is-owl-carousel" in element_data.attr) {
                    el = BaseCarouselComponent;
                    props = {component_data: element_data};
                    return [el, props];
                }
                if (
                    "deepsel-dropdown" in element_data.attr ||
                    (element_data.attr.class &&
                        element_data.attr.class.includes("dropdown-menu"))
                ) {
                    el = BaseDropdownComponent;
                    props = {component_data: element_data};
                    return [el, props];
                }
                if ("deepsel-form-data" in element_data.attr) {
                    el = BaseFormDataComponent;
                    props = {component_data: element_data};
                    return [el, props];
                }
                if ("deepsel-video" in element_data.attr) {
                    el = BaseVideoComponent;
                    props = {component_data: element_data};
                    return [el, props];
                }

            }

            // if (this.$store.state.edit_mode) {
            if (Object.keys(ComponentMap).includes(element_data.tag)) {
                el = ComponentMap[element_data.tag];
                props = {component_data: element_data};
            } else if (this.is_special_div(element_data)) {
                el = ComponentMap.special_div;
                props = {component_data: element_data};
            } else if (this.is_special_section(element_data)) {
                el = ComponentMap.special_section;
                props = {component_data: element_data};
            }
            // }

            return [el, props];
        },
        get_attrs(element_data, context) {
            let attrs = {}
            if (element_data.attr) {

                for (let key in element_data.attr) {
                    if (key === 'style' && is_object(element_data.attr[key])) continue
                    if (key === 'type' && element_data.tag === 'button') element_data.attr[key] = 'button'
                    let value = element_data.attr[key];

                    if (key === 'class') {
                        // if (context && context.index !== 0) {
                        //     attrs[key] = (Array.isArray(value) ? value.join(" ") : value).replace('active', '')
                        // } else {
                        // }
                            attrs[key] = (Array.isArray(value) ? value.join(" ") : value)
                    }
                }
                if (attrs.class && attrs.class.includes('nav-link')) {
                    attrs.href = `#nav-${this.data_id}-${context.index + 1}`
                }

                if (attrs.class && attrs.class.includes('tab-pane')) {
                    attrs.id = `nav-${this.data_id}-${context.index + 1}`
                }

                if (element_data.tag === "script") {
                    attrs.type = "text/javascript";
                    attrs.defer = false;
                    attrs.async = false;
                }

                let att_data = get_att_data(element_data, this.$store.state.common_data)
                if (att_data) {
                    for (let key in att_data) {
                        attrs[key] = att_data[key]
                    }
                }

                this.replace_origin_urls(attrs, element_data)

                return attrs
            }
        },
        get_style(element_data) {
            if (element_data.attr && is_object(element_data.attr.style)) {
                let origin = this.$store.state.origin;
                let style = element_data.attr.style
                if (style.backgroundImage) {
                    if (
                        !style.backgroundImage.includes("https://") &&
                        !style.backgroundImage.includes("http://")
                    ) {
                        let background_img_path = style.backgroundImage
                            .replace('url(', '')
                            .replace(')', '')
                            .replace(/'/g, '')

                        // background_img_path = background_img_path.replace(/\\/g, " ")
                        if (background_img_path.slice(0, 1) !== "/") {
                            background_img_path = "/" + background_img_path;
                        }

                        style.backgroundImage = `url(${origin + background_img_path})`
                    }
                }
                return style
            } else return null
        },
        replace_origin_urls(attrs, element_data) {
            let origin = this.$store.state.origin;
            if (origin) {
                if (element_data.tag === "script" || element_data.tag === "img") {
                    if (attrs.src) {
                        if (
                            !attrs.src.includes("https://") &&
                            !attrs.src.includes("http://")
                        ) {
                            attrs.src = attrs.src.replace(/\\/g, "/");
                            if (attrs.src.slice(0, 1) !== "/") {
                                attrs.src = "/" + attrs.src;
                            }
                            attrs.src = origin + attrs.src;
                        }
                    }
                } else if (element_data.tag === "link") {
                    if (
                        !attrs.href.includes("https://") &&
                        !attrs.href.includes("http://") &&
                        attrs.href !== '#' &&
                        !attrs.href.includes('javascript:void')
                    ) {
                        attrs.href = attrs.href.replace(/\\/g, "/");
                        if (attrs.href.slice(0, 1) !== "/") {
                            attrs.href = "/" + attrs.href;
                        }
                        attrs.href = origin + attrs.href;
                    }
                }
            }
        },
        get_children(element_data, createElement, context) {
            let children = []
            let deepsel_text_data = null

            if (element_data.child) {
                for (let [index, item] of element_data.child.entries()) {
                    if (item.node === "element") {
                        if (is_object(item.attr) && !is_empty(item.attr) && item.attr.class && item.attr.class.includes('nav-item')) {
                            children.push(this.make_element_tree(createElement, item, {}, {index}))
                        } else if(is_object(item.attr) && !is_empty(item.attr) && item.attr.class && item.attr.class.includes('tab-pane')) {
                            children.push(this.make_element_tree(createElement, item, {}, {index}))
                        } else {
                            children.push(this.make_element_tree(createElement, item, {}, context));
                        }


                    } else if (item.node === "text") {
                        children.push(deepsel_text_data ? deepsel_text_data : item.text)
                    }
                }
            }

            if (this.transform && element_data.tag === 'form') {
                children.push(createElement("style", [this.transform]))
            }
            return children
        }
    },
}